import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  baseURL: 'https://www.api-apsth.co/api-main/',

  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
